<template>
  <div class="p-2">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ModalContent"
}
</script>

<style scoped>

</style>
