export const VoyageBelek = {
    title: 'Voyage Belek',
    logo: {
      src: 'VoyageBelek.png',
      height: '60'
    },
    languages: [
        {
            id: 1,
            lang: "TR",
            langNative: "Türkçe",
        }, {
            id: 2,
            lang: "EN",
            langNative: "English",
        }, {
            id: 3,
            lang: "DE",
            langNative: "Deutsch",
        }, {
            id: 4,
            lang: "RU",
            langNative: "Русский",
        }
    ],
    menu: [
        {
            id: 1,
            key: 'information',
            icon: 'infobook',
            link: {
                url: '/info/1',
                target: '_self'
            },
            detail: {
                tr: 'https://drive.google.com/file/d/1nIKansQOp1cGSPIajP0h9ygNxsusghCt/preview',
                en: 'https://drive.google.com/file/d/1EZfo8gc8QYjP0tE2rJ1azLx2wXMPd3Jm/preview',
                ru: 'https://drive.google.com/file/d/1oiO0-iyJbnyTf5Mmq0iz0Fx0XG0Ftc3m/preview',
                de: 'https://drive.google.com/file/d/1YczeG-ilHZZoSh44te8NymMTj_HVzCRx/preview',
            }
        },
        {
            id: 2,
            key: 'restaurants',
            icon: 'food',
            link: {
                url: 'https://voyage-belek.gomymenu.com',
                target: '_blank'
            },
        },
        {
            id: 3,
            key: 'map',
            icon: 'map',
            link: {
                url: '/info/3',
                target: '_self'
            },
            detail: {
                tr: 'https://drive.google.com/file/d/1EtDXqY_zEdZ3wmrMDnzqXsjHYK8yysnu/preview',
                en: 'https://drive.google.com/file/d/1EtDXqY_zEdZ3wmrMDnzqXsjHYK8yysnu/preview',
                ru: 'https://drive.google.com/file/d/1EtDXqY_zEdZ3wmrMDnzqXsjHYK8yysnu/preview',
                de: 'https://drive.google.com/file/d/1EtDXqY_zEdZ3wmrMDnzqXsjHYK8yysnu/preview',

            }
        },
    ]
}
